import { mapActions } from "vuex";

export default {
  name: "Logout",
  methods: {
    ...mapActions({
      doLogout: "auth/logout",
    }),
  },
  mounted() {
    this.doLogout({ doRedirect: true });
  },
  render: () => null,
};
